import React, { Component, useState } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams
} from "react-router-dom";

import { setHeaderInfo } from './Features/header.slice.js';
import { setLinks } from './Features/link.slice.js';
import { setMenus } from './Features/menu.slice.js';
import { setOverlay } from './Features/overlay.slice.js';
import { setBorderBottom } from './Features/borderbottom.slice.js';
import { setAboutSection } from './Features/aboutsection.slice.js';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Url from './Constants/Url.js';


import './App.css';
import Home from './Pages/home';
import Admin from './Pages/admin.js';
import { setRealisationsItems, setRealisationsSection, setSectionItems, setServicesSection, setSponsoringItems, setSponsoringSection } from './Features/section.slice.js';


const App =()=> {

  const dispatch = useDispatch();
    
  const [ isLoading, setIsLoading ] = useState(true);

  const loadHeader = async() =>{
    const response = await axios.get(`${Url.areus_Api}header/info`);
    dispatch(setHeaderInfo(response.data.results[0]));
  }

  const loadLinks = async() =>{
    const response = await axios.get(`${Url.areus_Api}links/all`);
    dispatch(setLinks(response.data.results));
  }

  const loadMenus = async() =>{
    const response = await axios.get(`${Url.areus_Api}menus/all`); 
    dispatch(setMenus(response.data.results.filter(m => m.published === 1)));
  }

  const loadOverlay = async() =>{
    const response = await axios.get(`${Url.areus_Api}overlay`); 
    dispatch(setOverlay(response.data.results[0]));
  }

  const loadBorderBottom = async() =>{
    const response = await axios.get(`${Url.areus_Api}borderbottom`); 
    dispatch(setBorderBottom(response.data.results));
  }

  const loadAboutSection = async() =>{
    const response = await axios.get(`${Url.areus_Api}aboutsection`); 
    dispatch(setAboutSection(response.data.results[0]));
  }

  const loadSection = async() =>{
    const response = await axios.get(`${Url.areus_Api}section`); 
    dispatch(setServicesSection(response.data.results));
    dispatch(setRealisationsSection(response.data.results));
    dispatch(setSponsoringSection(response.data.results));

    const response2 = await axios.get(`${Url.areus_Api}section/items`); 
    dispatch(setSectionItems(response2.data.results));
    dispatch(setRealisationsItems(response2.data.results));
    dispatch(setSponsoringItems(response2.data.results));
  }

  if(isLoading){
    loadHeader();
    loadLinks();
    loadMenus();
    loadOverlay();
    loadBorderBottom();
    loadAboutSection();
    loadSection();
    setIsLoading(false);
  }


    return (
      <div className = "wrapper" >
        <Router>
          <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/admin" element={<Admin />} />
          </Routes>
        </Router>
      </div>
    );

}
export default App;
