import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState:{
    user:undefined
  },
  reducers:{
    setUser:(state, {payload})=>{
      console.log("setUser, user.slice.js");
      console.log(payload);
      state.user = payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
