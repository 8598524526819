import React from 'react';
import Header from "../Components/header.js";
import Footer from "../Components/footer.js";
import Testimonials from "../Components/testimonials.js";
import Projects from "../Components/projects.js";
import Overlay from "../Components/overlay.js";
import BorderBottom from "../Components/borderbottom.js";
import AboutSection from "../Components/aboutsection.js";
import OurTeamSection from "../Components/ourteamsection.js";
import PricingSection from "../Components/pricingsection.js";
import FAQSection from "../Components/faqsection.js";
import NewsSection from "../Components/newssection.js";
import ServicesSection from "../Components/servicessection.js";
import ContactSection from "../Components/contactsection.js";
import { useSelector } from 'react-redux';
import Login from '../Components/login.js';
import '../admin.css';
import '../index.css';

const Admin = () => {

  const user = useSelector(state => state.user.user);

    return (
     
<>
      {
        user !== undefined ?
        <div id="admin" >
        <div className = "site-wrap" >
        <Login />
        </div>
        </div>
        :
        <div >
        <div className = "site-wrap" >
       
        </div>
        </div>
      }
</>    


    );
}



export default Admin;
