import React, { useRef, useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Url from '../Constants/Url';

const ContactSection = () => {

    const from = "contact@leadev.fr";
    const subject = "AREUS WEBSITE - Contact";
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState('');

    const sendMessage = async (e) => {
        e.preventDefault(); // Prevent form submission
        console.log(fullName + " " + email + " " + message);

/*         if (!recaptchaValue) {
            setError("Please complete the reCAPTCHA verification");
            return;
        } */

        let status = "";
        //const msg = { to: `${email}`, from: `${from}`, subject: `${subject}`, text: `${message}` };


        if(fullName !=="" && email !=="" && message!==""){

            let msg = {
                personalizations: [
                  {
                    to: [{ email: 'contact@areus-developpement.com' }, { email: 'contact@leadev.fr' }, { email: 'anthony.ribeiro.as@gmail.com' }],
                    dynamic_template_data: {
                      // Fill in the dynamic template data as per your template's requirements
                      // For example:
                      message: `${message}`, // Sanitize message
                      fullname: `${fullName}`, // Sanitize fullName
                      email: `${email}`, // Sanitize email
                    },
                  },
                ],
                from: { email: 'contact@leadev.fr' },
                template_id: 'd-86c338b2a597433c901102eef7349e7b', // Replace with your template ID
              };
              
    
    
            await axios.post(`${Url.areus_Api}contact`, msg)
                .then(res => {
                    status = "OK";
                    setSuccess("Votre message a bien été envoyé");
                    setFullName("");
                    setEmail("");
                    setMessage("");
                    setRecaptchaValue('');
                    setTimeout(() => setSuccess(''), 5000); // Reset success message after 5 seconds
                })
                .catch(err => {
                    status = "KO";
                    setError("Une erreur est intervenue lors de l'envoi de votre message. Veuillez vérifier le formulaire.");
                    setTimeout(() => setError(''), 5000); // Reset error message after 5 seconds
                });
    
                let msg2 = {
                    personalizations: [
                      {
                        to: [{ email: email }],
                        dynamic_template_data: {
                          // Fill in the dynamic template data as per your template's requirements
                          // For example:
                          message: `${message}`, // Sanitize message
                          fullname: `${fullName}`, // Sanitize fullName
                          email: `${email}`, // Sanitize email
                        },
                      },
                    ],
                    from: { email: 'contact@areus-developpement.com' },
                    template_id: 'd-67391cd4577e436398f125c2c6c12f24', // Replace with your template ID
                  };
        
                await axios.post(`${Url.areus_Api}contact`, msg2);

        }else{
            setError("Veuillez renseigner tous les champs du formulaire");
            setTimeout(() => setError(''), 5000);
        }

        
    }

    return (
        <div>
            <div className="site-section bg-light" id="contact-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 text-center">
                            <span className="sub-title"> En Savoir Plus </span>
                            <h2 className="font-weight-bold text-black"> NOUS CONTACTER </h2>
                            <p className="mb-5">Vous avez un projet, une demande spécifique ou une question, veuillez nous contacter via le formulaire ci-dessous.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <form action="#" className="p-5 bg-white" onSubmit={(e) => sendMessage(e)}>
                                {success && <div className="alert alert-success">{success}</div>}
                                {error && <div className="alert alert-danger">{error}</div>}
                                <div className="row form-group">
                                    <div className="col-md-12 mb-3 mb-md-0">
                                        <label className="font-weight-bold" htmlFor="fullname">NOM Prénom</label>
                                        <input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} id="fullname" className="form-control" placeholder="NOM Prénom" />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="font-weight-bold" htmlFor="email"> Email </label>
                                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" className="form-control" placeholder="Email Address" />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="font-weight-bold" htmlFor="message"> Message </label>
                                        <textarea name="message" value={message} onChange={(e) => setMessage(e.target.value)} id="message" cols="30" rows="5" className="form-control" placeholder="Message"></textarea>
                                    </div>
                                </div>
{/*                                 <div className="row form-group">
                                    <div className="col-md-12">
                                        <ReCAPTCHA  sitekey={process.env.REACT_APP_SITE_KEY}
                                            onChange={(value) => setRecaptchaValue(value)}
                                        />
                                    </div>
                                </div> */}
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <input type="submit" value="Envoyer" className="btn btn-primary rounded-0 btn-lg" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactSection;
