import React, { useState } from 'react';
import '../admin.css';
import '../index.css';
import Url from '../Constants/Url';
import { setUser } from '../Features/user.slice';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const Login =()=> {

    const dispatch = useDispatch();
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [error, setError] = useState('');

    const getUser = async() =>{
        let user = {
            email:email, 
            password :password
        };

        try {
            const response = await axios.post(`${Url.areus_Api}user/login`, user);

            if(response.data.results[0] !== undefined){
                dispatch(setUser(response.data.results[0]));
                
            }
            else{
                setError("Erreur de connexion, utilisateur et/ou mot de passe invalide(s).");
            }
        } catch (error) {
            console.error('Login failed:', error.response.data.message);
            setError(error.response.data.message);
        }

      }

      const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        getUser();
    }

    return(

        <div style={{fontFamily:'Nunito Sans'}}>

        <div class="screen-1">
        <div className='logo' style={{paddingTop:20, paddingBottom:20, margin:'auto'}}>
        <img  src={`${Url.areus_Cdm}/Home/fav.jpg`} width="150" height="150" style={{borderRadius: '25%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}/>
        </div>
        
        <div style={{marginBottom:50}}>
        {error ? <div style={{ color: 'white' }}>{error}</div>
        :
        <div style={{ color: 'white' }}>Bienvenue !</div>
        }
        </div>

        <div class="email">
            <label for="email">Email Address</label>
            <div class="sec-2">
            <ion-icon name="mail-outline"></ion-icon>
            <input type="email" name="email" 
            value={email} onChange={e => setEmail(e.target.value)}/>
            </div>
        </div>
        <div class="password">
            <label for="password">Password</label>
            <div class="sec-2">
            <ion-icon name="lock-closed-outline"></ion-icon>
            <input class="pas" type="password" name="password" placeholder="············"
             value={password} onChange={e => setPassword(e.target.value)}/>
            <ion-icon class="show-hide" name="eye-outline"></ion-icon>
            </div>
        </div>
        <button class="login" onClick={handleSubmit}>Login </button>
        <div class="footer"><span>Signup</span><span>Forgot Password?</span></div>
        </div>

        </div>


    )
}

export default Login;